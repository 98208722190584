@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&family=DM+Sans:opsz,wght@9..40,400..500&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

.wrap {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-out;
}

body {
  background: #0D0E11;
  color: #A3ABB2;
  font-family: 'DM Mono', serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #A3ABB2;
  font-family: 'DM Sans', sans-serif;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.56px;
  margin: 0 0 4px 0;
}

h3 {
  color: #A3ABB2;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 3px;
}

h4 {
  color: #42495F;
  font-family: 'DM Mono', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 33px;
}

header {
  align-items: center;
  background: rgba(15, 17, 23, 0.40);
  backdrop-filter: blur(4.449999809265137px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  font-size: 14px;
  height: 62px;
  justify-content: space-between;
  margin-bottom: 64px;
  padding: 14px 42px;

  .left {
    div {
      display: inline-block;
    }

    img {
      height: 18px;
      margin-right: 12px;
      position: relative;
      top: -2px;
      vertical-align: middle;
      width: 18px;
    }

    .location {
      margin-right: 24px;
    }
  }
}

header::after {
  border-radius: 629px;
  background: radial-gradient(44.2% 44.2% at 50% 55.8%, #1ABCFE 0%, rgba(91, 64, 255, 0.00) 100%);
  content: '';
  filter: blur(99.19999694824219px);
  height: 550px;
  display: block;
  left: calc(50% - 315px);
  position: absolute;
  top: -410px;
  width: 630px;
}

.button {
  border: 1px solid #A3ABB2;
  border-radius: 29px;
  color: #A3ABB2;
  display: inline-block;
  font-weight: 500;
  letter-spacing: -0.28px;
  padding: 7px 21px;
}

.home {
  display: flex;
  gap: 60px;
  margin-bottom: 88px;

  .left {
    backdrop-filter: blur(4.449999809265137px);
    background: #0f111766;
    border: 1px solid #14161C;
    border-radius: 20px;
    text-align: center;
    padding: 32px 0;
    width: 320px;

    .top {
      border-bottom: 1px solid #14161c;
      padding-bottom: 25px;
    }

    .face {
      border-radius: 118px;
      background: url('jmliao.png') lightgray 50% / cover no-repeat;
      background-blend-mode: luminosity;
      height: 98px;
      margin: 0 auto 10px auto;
      position: relative;
      width: 98px;
    }

    .face-available {
      background: #0e0f13;
      border-radius: 50%;
      bottom: 0;
      height: 28px;
      padding:  5px;
      position: absolute;
      right: 0;
      width: 28px;
    }
    
    .face-available::before {
      animation: available 0.5s infinite alternate; /* Run animation infinitely and alternate between increasing and decreasing size */
      background: #50f24a;
      border-radius: 50%;
      content: '';
      display: block;
      height: 18px;
      transform-origin: center;
      width: 18px;
    }

    .profession {
      color: #42495F;
    }

    .bottom {
      padding: 32px 16px 0 16px;
    }

    .history {
      padding-left: 10px;
    }

    .work {
      border-left: 1px solid #14161c;
      color: #3C4358;
      margin-bottom: 26px;
      padding-left: 18px;
      position: relative;
      text-align: left;

      .jobtitle {
        color: #A3ABB2;
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        margin-bottom: 9px;
      }

      .company {
        font-size: 15px;
        margin-bottom: 9px;
      }

      .year {
        font-size: 13px;
      }
    }

    .work::before {
      background: #A3ABB2;
      border-radius: 50%;
      content: '';
      display: block;
      height: 6px;
      left: -3.5px;
      position: absolute;
      width: 6px;
    }

    .skills {
      max-width: 100%;
      overflow: hidden;
    }

    .skillsline {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    .direction-left {
      animation: scrollLeft 30s infinite linear;
    }

    .direction-right {
      animation: scrollRight 30s infinite linear;
    }

    .carousel-one, .carousel-two {
      display: inline-block;
      margin-right: 10px;
    }

    .skillfeed {
      display: flex;
      gap: 10px;
    }

    .skillpair {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      gap: 10px;

      img {
        height: 16px;
        position: relative;
        top: -2px;
        vertical-align: middle;
        width: 16px;
      }
    }

    .skill {
      border-radius: 14px;
      background: #14161C;
      backdrop-filter: blur(4.449999809265137px);
      padding: 8px 16px 9px 17px;
    }

  }
}

.work-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 24px;
  max-width: 736px;

  .work {
    max-width: 356px;

    .thumbnail {
      margin-bottom: 12px;
    }

    .tags {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;

      .tag {
        background: #0e1116;
        border: 1px solid #16181d;
        border-radius: 210px;
        color: #3c4358;
        padding: 3px 14px;
      }
    }

    .title {
      color: #A3ABB2;
      font-family: 'DM Sans', sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3;
    }
  }
}

.techstack {
  display: flex;
  justify-content: space-between;
  margin-bottom: 129px;

  .tech {
    background: #14161C;
    border-radius: 16px;
    max-height: 72px;
    max-width: 72px;
    padding: 16px;
    text-align: center;
    width: 100%;
  }
}

.cta {
  text-align: center;

  .top {
    color: #3C4358;
    font-family: 'DM Mono', serif;
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .middle {
    background: -webkit-linear-gradient(#fff, #A3ABB2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1;
    margin: 0 auto 24px auto;
    max-width: 400px;
  }

  .bottom {
    color: #42495F;
    font-family: 'DM Mono', serif;
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 36px;
  }

  a {
    background: #0099FF;
    border-radius: 29px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 1.25;
    margin-bottom: 100px;
    padding: 16px 31px;

    &:hover {
      background: #333;
    }
  }
}

footer {
  color: rgba(207, 212, 215, 0.30);
  padding: 30px 0;
  
  .wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .right {
    display: flex;
    gap: 15px;
  }

  img {
    height: 20px;
    width: 20px;
  }
}


@keyframes available {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}

@keyframes scrollLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}